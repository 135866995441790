<template>
  <div>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("全部订单") }}</div>
      </div>
      <div class="tabsContainer flex" style="margin: 26px 26px 0 26px">
        <el-tabs v-model="form.status_name" type="card" @tab-click="form.page=1;getData(form)">
          <el-tab-pane :label="temporaryText" name="临时保存待提出"></el-tab-pane>
          <el-tab-pane :label="allText" name="全部"></el-tab-pane>
          <el-tab-pane :label="offerText" name="报价中"></el-tab-pane>
          <el-tab-pane :label="obligationText" name="等待付款"></el-tab-pane>
          <el-tab-pane :label="purchaseText" name="正在购买"></el-tab-pane>
          <el-tab-pane :label="purchasedText" name="采购完了"></el-tab-pane>
          <el-tab-pane :label="shippedText" name="出货完成"></el-tab-pane>
          <el-tab-pane :label="deleteText" name="已取消"></el-tab-pane>
        </el-tabs>
        <div class="tabsRightContainer"></div>
      </div>
      <div class="flexAndCenter orderScreenContainer">
        <div style="margin-bottom:10px"
             class="orderKeywordsContainer flexAndCenter">
          <div :style="employeesAccountList.length==0?'min-width: 70px':''" class="fontSize14 color333"
               style="width:70px;">{{ $fanyi("订单号") }}：
          </div>
          <el-input v-model="form.keywords" :placeholder="$fanyi('请输入订单号搜索')"
                    :style="employeesAccountList.length>0?'width: 130px;':''"/>
        </div>
        <div class="orderKeywordsContainer flexAndCenter" style="margin:0 0 10px 10px">
          <div :style="employeesAccountList.length==0?'min-width: 70px':''" class="fontSize14 color333"
               style="width:70px;">{{ $fanyi("管理番号") }}：
          </div>
          <el-input v-model="form.for_sn" :placeholder="$fanyi('管理番号')"
                    :style="employeesAccountList.length>0?'width: 130px;':''"/>
        </div>
        <div
            :class="employeesAccountList.length==0?'newOrderGoodNameContainer flexAndCenter':'orderGoodNameContainer flexAndCenter'"
            style="margin:0 0 10px 10px">
          <div :style="employeesAccountList.length==0?'min-width:56px':''" class="fontSize14 color333">
            {{ $fanyi("商品名") }}：
          </div>
          <el-input v-model="form.goods_title_search" :placeholder="$fanyi('请输入商品名搜索')"
                    :style="employeesAccountList.length>0?'width: 160px;':''"/>
          <div class="languageListContainer">
            <div v-for="(item,index) in languageList" :key="index" @click="languageIndex=index;$forceUpdate"
                 :class="languageIndex===index?'activeLanguage':''">
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="employeesAccountList.length>0" class="orderKeywordsContainer flexAndCenter"
             style="margin:0 0 10px 10px">
          <div class="fontSize14 color333">{{ $fanyi("子账户管理") }}：</div>
          <el-select v-model="form.children_user_id" size="small">
            <el-option
                v-for="item in employeesAccountList"
                :key="item.children_user_id"
                :label="item.children_realname"
                :value="item.children_user_id">
            </el-option>
          </el-select>
        </div>
        <div :style="employeesAccountList.length==0?'margin-left:10px':''" class="orderTimeContainer flexAndCenter">
          <div class="fontSize14 color333">{{ $fanyi("提出日期") }}：</div>
          <el-date-picker
              v-model="form.start_time"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="00:00:00"
              style="width: 160px;"
              @change="startTimeChange"
              :placeholder="$fanyi('开始时间')">
          </el-date-picker>
          <div class="fontSize14 color333" style="margin:0 14px">{{ $fanyi("至") }}</div>
          <el-date-picker
              v-model="form.end_time"
              type="datetime"
              style="width: 160px;"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="23:59:59"
              @change="endTimeChange"
              :placeholder="$fanyi('结束时间')">
          </el-date-picker>
        </div>
        <div class="orderScreenBtnListContainer flexAndCenter" style="margin-left:26px">
          <div @click="getData(form)">{{ $fanyi("筛选") }}</div>
          <div @click="resetOrder">{{ $fanyi("重置") }}</div>
        </div>
      </div>
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="orderNumber">
            <span class="tableHeadCon flexAndCenterAndCenter" style="height: 56px" :title="$fanyi('订单号')">
              <span style="display: inline-block;height: 56px;line-height: 56px">{{ $fanyi("订单号") }}</span>
              <img src="../../../../assets/icon/sort.png" alt="" @click="updateGoodsSort" class="sortIcon">
            </span>
          </li>
          <li class="orderSorting">
            <span class="tableHeadCon" :title="$fanyi('订单番数')">{{ $fanyi("订单番数") }}</span>
          </li>
          <li class="datePresentation">
            <span class="tableHeadCon" :title="$fanyi('提出日期')">{{ $fanyi("提出日期") }}</span>
          </li>
          <li class="datePresentation" style="width: 276px">
            <span :title="$fanyi('订单跟进注意事项')" class="tableHeadCon">{{ $fanyi("订单跟进注意事项") }}</span>
          </li>
          <li class="upLoad">
            <span class="tableHeadCon" :title="$fanyi('导出excel')">{{ $fanyi("导出excel") }}</span>
          </li>
          <li class="orderStatus">
            <span class="tableHeadCon" :title="$fanyi('订单状态')">{{ $fanyi("订单状态") }}</span>
          </li>
          <li class="operation">
            <span class="tableHeadCon" :title="$fanyi('操作')">{{ $fanyi("操作") }}</span>
          </li>
        </ul>
        <div v-show="tableData.length>0">
          <ul style="border-bottom: 1px solid #DFDFDF;"
              v-for="(item, index) in tableData"
              :key="$fanyi('表格') + index">
            <div class="obody flexAndCenter" style="border-bottom:none">
              <li class="orderNumber">
                <div class="orderNumberContainer">
                  <el-popover v-if="item.children_user!=null" placement="top" trigger="hover">
                    <div>
                      <div>社員用アカウント情報</div>
                      <div>氏名：{{ item.children_user.children_realname }}</div>
                    </div>
                    <img slot="reference"
                         class="cursorPointer"
                         src="../../../../assets/subAccount.png"
                         style="margin-top: 2px;margin-right: 5px"
                    />
                  </el-popover>
                  <div v-if="item.part_pay_status>0" class="inBatchesPaymentIconContainer">
                    <img alt="" src="../../../../assets/purseIcon.png">
                    <div>分割</div>
                  </div>
                  <router-link
                      :style="item.part_pay_status>0&&item.children_user==null?'max-width: 128px;display: inline-block;overflow-wrap:break-word;':item.part_pay_status>0&&item.children_user!=null?'max-width: 94px;display: inline-block;overflow-wrap:break-word;':''"
                      :to="{path:'/OrderDetails',query: {order_sn: item.order_sn}}"
                      target="_blank">
                    {{ item.order_sn }}
                  </router-link>
                  <div class="showChildrenBtn" v-show="item.showStatus===false"
                       @click="item.showStatus=!item.showStatus"><i class="el-icon-arrow-right"></i>
                  </div>
                  <div class="showChildrenBtn" @click="item.showStatus=!item.showStatus"
                       v-show="item.showStatus===true"><i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </li>
              <li class="orderSorting">
                {{ item.all_count }}
              </li>
              <li class="datePresentation">
                <el-popover placement="bottom" popper-class="pOrderTimePopover" trigger="hover">
                  <div class="pOrderTimeListContainer">
                    <div class="pOrderTimeHeaderContainer">
                      <div>{{ $fanyi('提出日期') }}</div>
                      <div>{{ $fanyi('付款日期') }}</div>
                      <div>{{ form.status_name !== '已取消' ? $fanyi("购买完成日期") : $fanyi("取消日期") }}</div>
                    </div>
                    <div class="pOrderTimeFooterContainer">
                      <div class="timeContainer">
                        <div>{{ item.created_at }}</div>
                      </div>
                      <div class="timeContainer">
                        <div>{{ item.pay_at !== '' ? item.pay_at : '————' }}</div>
                      </div>
                      <div class="timeContainer">
                        <div v-if="form.status_name!=='已取消'">
                          {{ item.buy_success_at !== '' ? item.buy_success_at : '————' }}
                        </div>
                        <div v-else>{{ item.cancel_at !== '' ? item.cancel_at : '————' }}</div>
                      </div>
                    </div>
                  </div>
                  <div slot="reference" style="text-decoration: underline;color: #000000;cursor: pointer">
                    {{ item.created_at }}
                  </div>
                </el-popover>
              </li>
              <li class="datePresentation" style="width: 276px">
                <el-input v-if="item.status!=10" v-model="item.follow_remark" resize="none"
                          type="textarea" @blur="updateFollowRemark(item)"/>
                <div v-else>————</div>
              </li>
              <li class="upLoad">
                <img src="../../../../assets/downloadIcon.png" alt="" @click="orderExcelDownload(item)"/>
              </li>
              <li style="font-size: 12px; text-align: center" class="orderStatus">
                {{ $fanyi(item.status_name) }}
              </li>
              <li class="operation">
            <span style="cursor: pointer;color: #B4272B;text-decoration: underline;font-size: 12px"
                  @click="cancelOrder(item.order_sn)"
                  v-show="item.status==30||item.status==10||item.status==20||(item.part_pay_status==1&&item.status>30)">{{
                $fanyi("取消")
              }}</span>
                <span style="cursor: pointer;color: #B4272B;text-decoration: underline;font-size: 12px"
                      @click="removeOrder(item.order_sn)"
                      v-show="item.status==-2">{{ $fanyi("删除") }}</span>
                <button
                    v-show="item.status==30||((item.part_pay_status==1||item.part_pay_status==3)&&item.status>30)"
                    class="searchBtn"
                    @click="$fun.routerToOrderDetailsPage(item.order_sn)">
                  {{ $fanyi("付款") }}
                </button>
              </li>
            </div>
            <div v-show="item.showStatus===true">
              <div class="ordersListHeader">
                <div class="goodsNo">{{ $fanyi("番号") }}</div>
                <div class="goodsImage">{{ $fanyi("图片") }}</div>
                <div class="goodsAttribute">{{ $fanyi("商品属性") }}</div>
                <div class="purchaseData">現在</div>
                <div class="option">オプション</div>
                <div class="ordersNumber">倉庫納品情報</div>
                <div class="goodsRemark">{{ $fanyi("备注") }}</div>
              </div>
              <div>
                <div class="ordersListFooter" v-for="(goodsItem,goodsIndex) in item.order_detail" :key="goodsIndex">
                  <div :style="form.for_sn!=''&&goodsItem.for_sn==form.for_sn?'background: #FCF2F2;':''"
                       class="goodsNo">
                    <div class="flexAndCenterAndCenter" style="flex-direction: column">
                      <div class="fontSize14">{{ goodsIndex + 1 }}</div>
                      <el-popover placement="bottom" trigger="hover">
                        <div class="fontSize14">{{ goodsItem.for_sn }}</div>
                        <div slot="reference" class="fontSize14 u-line"
                             style="margin-top: 5px;width: 70px;text-align: center">{{
                            goodsItem.for_sn
                          }}
                        </div>
                      </el-popover>
                    </div>
                  </div>
                  <div class="goodsImage" style="flex-direction: column">
                    <el-popover placement="right" trigger="hover">
                      <el-image :src="goodsItem.pic"
                                style="width: 300px; height: 300px"
                      />
                      <el-image :src="goodsItem.pic"
                                slot="reference"
                                @click="$fun.toCommodityDetails(goodsItem.goods_id,goodsItem.from_platform)"
                                style="width: 60px;height: 60px"
                      />
                    </el-popover>
                    <div v-if="goodsItem.from_platform==1688" class="goodsType">1688</div>
                  </div>
                  <div class="goodsAttribute">
                    <el-popover placement="top" trigger="hover" v-if="goodsItem.detail!=null">
                      <div>
                        <div v-for="(detailItem,detailIndex) in goodsItem.detail" :key="detailIndex">
                          {{ detailItem.key }}：{{ detailItem.value }}
                        </div>
                      </div>
                      <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                        <div class="detailListContainer">
                          <div class="u-line" v-for="(detailItem,detailIndex) in goodsItem.detail" :key="detailIndex">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                        <div v-if="goodsItem.detail.length>3">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="purchaseData">
                    <div class="fontSize14">
                      <div>{{ $fanyi('数量') }}：{{ goodsItem.num }}</div>
                      <div>{{ $fanyi('单价') }}：{{ goodsItem.price }}</div>
                      <div>{{ $fanyi('运费') }}：{{ goodsItem.freight }}</div>
                    </div>
                  </div>
                  <div class="option">
                    <el-popover placement="top" trigger="hover" v-if="goodsItem.option!=null">
                      <div>
                        <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                          {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                        </div>
                      </div>
                      <div class="goodsAttributeDetailsContainer cursorPointer" slot="reference">
                        <div class="optionListContainer">
                          <div class="u-line" v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex">
                            {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                          </div>
                        </div>
                        <div v-if="goodsItem.option.length>3">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="ordersNumber">
                    <el-popover placement="top" trigger="hover">
                      <div>
                        <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                          <div>{{ tagItem.type_translate }}</div>
                          <div>{{ tagItem.no }}</div>
                          <div>{{ tagItem.goods_no }}</div>
                        </div>
                      </div>
                      <div slot="reference">
                        <div class="goodsTagDetailsContainer cursorPointer">
                          <div v-for="(tagItem,tagIndex) in goodsItem.order_detail_tag" :key="tagIndex">
                            <div class="u-line">{{ tagItem.type_translate }}</div>
                            <div class="u-line">{{ tagItem.no }}</div>
                            <div class="u-line">{{ tagItem.goods_no }}</div>
                          </div>
                        </div>
                        <div v-if="goodsItem.order_detail_tag.length>1">........</div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="goodsRemark fontSize14">
                    <div :title="goodsItem.client_remark" class="u-line-5" style="width:202px">
                      {{ goodsItem.client_remark }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div v-show="tableData.length===0&&loadingStatus===false" class="tableDataNullContainer">
          {{ $fanyi("暂无订单") }}
        </div>
      </div>
      <div style="padding: 30px 0;justify-content: center" class="flexAndCenter pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog";

export default {
  data() {
    return {
      form: {
        status_name: '全部',
        page: 1,
        pageSize: 10,
        keywords: '',
        start_time: '',
        order_by: 'desc',
        end_time: '',
        for_sn: '',
        goods_title_search: '',
        goods_title_search_language: '',
        created_by_type: '',
        children_user_id: '',
      },
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      loadingStatus: null,
      goodName: '',
      languageList: ['中', '日'],
      languageIndex: 0,
      total: 0,
      orderStatusName: '',
      temporaryText: this.$fanyi('临时保存') + '（0）',
      deleteText: this.$fanyi('已取消'),
      purchasedText: this.$fanyi('采购完了') + '（0）',
      purchaseText: this.$fanyi('正在购买') + '（0）',
      obligationText: this.$fanyi('等待付款') + '（0）',
      offerText: this.$fanyi('报价中') + '（0）',
      allText: this.$fanyi('全部订单') + '（0）',
      shippedText: this.$fanyi('出货完成') + '（0）',
      tableData: [],
      timeValue: [],
      orderList: [],
      employeesAccountList: [],
    };
  },
  components: {
    Dialog
  },
  computed: {},
  created() {
    this.form.page = 1;
    this.form.pageSize = 10;
    if (this.$route.query.status) {
      switch (this.$route.query.status) {
        case 'temporary':
          this.form.status_name = '临时保存待提出';
          break;
        case 'all':
          this.form.status_name = '全部';
          break;
        case 'offer':
          this.form.status_name = '报价中';
          break;
        case 'obligation':
          this.form.status_name = '等待付款';
          break;
        case 'purchase':
          this.form.status_name = '正在购买';
          break;
        case 'purchased':
          this.form.status_name = '采购完了';
          break;
        case 'shipped':
          this.form.status_name = '出货完成';
          break;
      }
    }
    this.getData();
    this.ordersSum();
    this.$api.childrenUserList({
      page: 1, pageSize: 999
    }).then((res) => {
      res.data.data.forEach((item) => {
        if (item.children_status === 1) {
          this.employeesAccountList.push(item)
        }
      })
    })
  },
  methods: {
    // 获取数据
    getData() {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.loadingStatus = true;
      this.tableData = [];
      if (this.form.goods_title_search !== '') {
        if (this.languageIndex === 0) {
          this.form.goods_title_search_language = 'zh'
        } else {
          this.form.goods_title_search_language = 'ja'
        }
      }
      if (this.form.children_user_id != '') {
        this.form.created_by_type = 'children'
      }
      this.$api.orderList(this.form).then((res) => {
        if (res.code != 0) {
          this.loading.close();
          this.loadingStatus = false;
          return
        }
        res.data.data.forEach((item) => {
          item.showStatus = false;
        })
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.form.page = res.data.currentPage;
        this.loading.close();
        this.loadingStatus = false;
        this.$forceUpdate();
      })
    },
    // 获取每个订单数量
    ordersSum() {
      this.$api.ordersSum().then((res) => {
        if (res.data.length == 0) return (this.allText = this.$fanyi('全部订单') + '（0）');
        res.data.forEach((item) => {
          switch (item.name) {
            case '已取消':
              this.deleteText = this.$fanyi('已取消');
              break;
            case '临时保存待提出':
              this.temporaryText = this.$fanyi('临时保存') + `（${item.count}）`;
              break;
            case '报价中':
              this.offerText = this.$fanyi('报价中') + `（${item.count}）`;
              break;
            case '等待付款':
              this.obligationText = this.$fanyi('等待付款') + `（${item.count}）`;
              break;
            case '正在购买':
              this.purchaseText = this.$fanyi('正在购买') + `（${item.count}）`;
              break;
            case '采购完了':
              this.purchasedText = this.$fanyi('采购完了') + `（${item.count}）`;
              break;
            case '出货完成':
              this.shippedText = this.$fanyi('出货完成') + `（${item.count}）`;
              break;
            case '全部':
              this.allText = this.$fanyi('全部订单') + `（${item.count}）`
              break;
          }
        })
      })
    },
    updateGoodsSort() {
      this.form.order_by === 'asc' ? this.form.order_by = 'desc' : this.form.order_by = 'asc';
      this.getData();
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData(this.form)
    },
    //判断开始时间不能晚于结束时间
    startTimeChange() {
      if (this.form.end_time !== '') {
        if (this.form.end_time < this.form.start_time) {
          this.$message.error(this.$fanyi("开始时间不能晚于结束时间"));
          this.form.start_time = '';
        }
      }
    },
    //判断结束时间不能早于开始时间
    endTimeChange() {
      if (this.form.start_time !== '') {
        if (this.form.start_time > this.form.end_time) {
          this.$message.error(this.$fanyi("结束时间不能早于开始时间"));
          this.form.end_time = '';
        }
      }
    },
    //获取订单导出需要的临时token，下载excel
    orderExcelDownload(item) {
      this.$api.getOrderDetailDownloadToken({
        order_sn: item.order_sn
      }).then((res) => {
        const a = document.createElement('a')
        let url = location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1 ? 'https://apiwww.rakumart.com' : 'https://jpapi.rakumart.cn';
        a.href = `${url}/client/download.orderDetailDownload?order_sn=${item.order_sn}&downloadToken=${res.data.downloadToken}`;
        a.target = '_blank';
        document.body.appendChild(a)
        a.click()
      });
    },
    //重置订单筛选条件
    resetOrder() {
      this.form.keywords = '';
      this.form.start_time = '';
      this.form.end_time = '';
      this.form.page = 1;
      this.form.for_sn = '';
      this.form.children_user_id = '';
      this.form.created_by_type = '';
      this.form.pageSize = 10;
      this.goodName = '';
      this.getData(this.form);
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form)
    },
    // 取消订单
    cancelOrder(order_sn) {
      this.$refs.dialog.open(
          this.$fanyi("是否取消该订单？"),
          () => {
            let datas = {
              order_sn: order_sn,
              delete_reason: this.$fanyi("删除"),
            };
            this.$api.orderCancel(datas).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.getData(this.form);
              this.ordersSum();
            });
          },
          () => {
          }
      );
    },
    // 删除订单
    removeOrder(order_sn) {
      this.$refs.dialog.open(
          this.$fanyi("是否删除该订单？"),
          () => {
            let datas = {
              order_sn: order_sn,
              remove_reason: this.$fanyi("删除"),
            };
            this.$api.orderRemove(datas).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.getData(this.form);
              this.ordersSum();
            });
          },
          () => {
          }
      );
    },
    //更新跟进备注
    updateFollowRemark(item) {
      if (item.follow_remark != '') {
        this.$api.followRemarkEdit({order_sn: item.order_sn, follow_remark: item.follow_remark}).then(() => {

        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";


/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.goodsType {
  width: 46px;
  height: 18px;
  display: inline-block;
  background: #FF4000;
  border-radius: 2px;
  margin-top: 6px;
  text-align: center !important;
  line-height: 18px;
  font-weight: bold;
  color: #fff !important;
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ordersListHeader {
  display: flex;
  background: #F9F9F9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  div {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    height: 42px;
    line-height: 42px;
  }
}

.ordersListFooter {
  display: flex;
  background: #FFF8EE;
  height: 120px;

  .ordersNumber, .goodsNo, .goodsImage, .goodsAttribute, .purchaseData, .quantityShipped, .option, .ordersNumber, .goodsRemark {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.searchBtn {
  width: 80px;
  height: 26px;
  background: #B4272B;
  border-radius: 6px;
  line-height: 26px;
  text-align: center;
  color: #FEFEFE;
  font-size: 12px;
}

.ordersNumber {
  width: 200px;

  .goodsTagDetailsContainer {
    width: 172px;
    height: 56px;
    overflow: hidden;

    div {
      text-align: left;
      color: #333333;
      font-size: 14px;
      width: 172px;
    }
  }
}

.goodsNo {
  width: 80px;
}

.goodsImage {
  width: 100px;
}

.goodsAttribute {
  width: 160px;
}

.goodsAttributeDetailsContainer {
  .optionListContainer, .detailListContainer {
    width: 119px;
    height: 56px;
    overflow: hidden;
  }

  div {
    text-align: left;
    color: #333333;
    font-size: 14px;
    width: 119px;
  }
}

.purchaseData {
  width: 160px;
}

.quantityShipped {
  width: 80px;
}

.option {
  width: 200px;
}

.goodsRemark {
  width: 202px;
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .tabsContainer {
    position: relative;
    overflow: hidden;

    .tabsRightContainer {
      flex: 1;
      height: 40px;
      border-bottom: 1px solid #B4272B;
      width: 100%;
    }

    /deep/ .el-tabs__header {
      border-bottom: none;
    }

    /deep/ .is-active {
      border-top: 1px solid #B4272B !important;
      border-right: 1px solid #B4272B !important;
      border-left: 1px solid #B4272B !important;
      border-bottom: 1px solid transparent !important;
    }

    /deep/ .el-tabs__item {
      border-radius: 10px 10px 0 0;
      border-top: 1px solid #DFDFDF;
      border-left: 1px solid #DFDFDF;
      border-right: 1px solid #DFDFDF;
      border-bottom: 1px solid #B4272B;
      padding: 0 15px;
    }

    /deep/ .el-tabs__nav {
      border: none;
    }
  }

  .orderScreenContainer {
    margin-left: 26px;
    padding-bottom: 15px;
    margin-right: 26px;
    border-bottom: 1px solid #DFDFDF;
    flex-wrap: wrap;

    .orderKeywordsContainer {
      /deep/ .el-input__inner {
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }
    }

    .orderGoodNameContainer, .newOrderGoodNameContainer {
      position: relative;

      /deep/ .el-input {
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }

      /deep/ .el-input__inner {
        width: 130px;
        height: 30px;
        background: #FFFFFF;
        border-radius: 6px;
        border: none;
        margin-left: 27px;
      }

      .languageListContainer {
        width: 40px;
        height: 20px;
        background: #FFFFFF;
        border: 1px solid #B4272B;
        border-radius: 4px;
        display: flex;
        position: absolute;
        top: 6px;
        left: 58px;

        div {
          flex: 1;
          color: #010101;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .activeLanguage {
          background: #B4272B;
          color: #fff;
        }
      }
    }

    .newOrderGoodNameContainer {
      /deep/ .el-input__inner {
        width: 158px;
        height: 30px;
        background: #FFFFFF;
        border-radius: 6px;
        border: none;
        margin-left: 27px;
      }
    }

    .orderTimeContainer {
      /deep/ .el-input__inner {
        width: 160px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        padding-left: 13px;
        padding-right: 0;
      }

      /deep/ .el-date-editor {
        .el-input__prefix {
          display: none;
        }
      }

      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }

    .orderScreenBtnListContainer {
      div {
        width: 76px;
        height: 30px;
        //background: #B4272B;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
      }

      div:first-child {
        color: #fff;
        background: #B4272B;
        margin-right: 10px;
      }

      div:last-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }

  }

  .dataBody {
    margin: 20px 26px 0;

    .oNO {
      width: 50px;
    }

    .orderNumber {
      width: 260px;

      .orderNumberContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 260px;

        a {
          color: #B4272B;
          text-decoration: underline;
          margin: 0 11px 0 7px;
        }

        .cursorPointer {
          margin-top: 2px;
          margin-right: 5px;
        }

        .mergeBtnContainer {
          width: 22px;
          height: 22px;
          background: #B4272B;
          border-radius: 6px;
          text-align: center;
          cursor: pointer;
          color: #FFFFFF;
          font-size: 14px;
        }

        .showChildrenBtn {
          height: 30px;
          background: #F0F0F0;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          cursor: pointer;

          .el-icon-arrow-right:before, .el-icon-arrow-down:before {
            font-weight: bold;
            font-size: 14px;
            padding-left: 0 !important;
          }

          div {
            color: #333333;
            font-size: 14px;
          }
        }
      }

      .sortIcon {
        cursor: pointer;
        width: 20px;
        margin-left: 20px;
      }

    }

    .datePresentation {
      width: 138px;
    }

    .orderSorting {
      width: 60px;
    }

    .upLoad {
      width: 90px;

      img {
        cursor: pointer;
      }
    }

    .orderStatus {
      width: 140px;
    }

    .operation {
      width: 112px;
    }

    .head {
      justify-content: center;
      background: #F0F0F0;
      font-size: 14px;
      color: #333;
      height: 56px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
    }

    .obody {
      text-align: center;
      height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }
}

.inBatchesPaymentIconContainer {
  width: 70px;
  min-width: 70px;
  height: 30px;
  background: #B4272B;
  border-radius: 6px;
  padding: 0 8px 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    color: #FFFFFF;
    font-size: 14px;
  }
}

.pagination {
  /deep/ .el-pagination .el-select .el-input {
    width: 110px;
  }
}
</style>
